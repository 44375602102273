#home-div {
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

#home-inner-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1200px;
}

#home-intro-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  margin: 1rem 0 1rem 0;
}

#home-content-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  margin: 0 0 1rem 0;
}

#featured-project-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
}

#featured-project-img {
  width: 70%;
  height: auto;
  border-radius: 5px;
  border: solid;
  border-color: var(--font-color);
}

#see-more-link {
  margin-top: 0.5rem;
}