#footer-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-top: solid;
    border-color: gainsboro;
    border-width: 1px;
    padding: 1rem 0 1rem 0;
}

#footer-inner-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    max-width: 1200px;
    font-size: 16px;
}

#footer-links-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.footer-link {
    font-size: 20px;
    padding: 0 4rem;
}

.footer-text {
    font-size: 14px;
}