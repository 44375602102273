.project-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  max-width: 700px;
  margin: 3rem 0;
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding: 1rem;
}

.project-div:hover {
  cursor: pointer;
}

.project-img {
  width: 90%;
  border-radius: 5px;
  border-color: var(--font-color);
}

.project-description {
  width: 80%;
  letter-spacing: 1px;
}