#navbar-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    width: 100%;
}

#navbar-inner-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    max-width: 1200px;
}

#brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

#headshot-img {
    width: 40px;
    height: auto;
    border-radius: 50%;
    margin-right: 1rem;
}

#navbar-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 40%;
}